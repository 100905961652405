.button {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  background: transparent;
  color: var(--text-color);
  border: 2px solid var(--border-color);
  font: inherit;
  line-height: 1;
  padding: 0em clamp(1em, 2vw, 1.7em);
  height: clamp(50px, 8vw, 75px);
  border-radius: var(--border-radius);

  overflow: hidden;
  transition: all var(--animation-duration);

  z-index: 1;
  margin: 4% 0;

  .button-content {
    display: flex;
  }
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h5 {
    margin: 0;
    font-size: clamp(10px, 1vw, 14.4px);
  }
  & > h4 {
    font-size: clamp(14.5px, 1.5vw, 19.2px);

    margin: 0;
  }
}

.button:hover
  /* ,
  .button:focus */ {
  color: var(--hover-text-color);
  border: 2px solid var(--hover-border-color);

  .logo {
    fill: var(--hover-text-color);
  }
  transform: translateY(-10px);
}

.logo {
  display: inline-block;
  fill: white;
  width: clamp(25px, 4vw, 40px);
  height: 100px;
  transition: all var(--animation-duration);
}

.button:hover > .logo {
  fill: black;
}

.button::before {
  content: "";
  margin: 0;
  padding: 0;
  z-index: -1;

  position: absolute;
  top: -4px;
  left: -10px;
  bottom: -4px;
  width: 150%;
  background-color: var(--hover-color);
  transform: translate(-110%, 0) skew(-30deg);
  transition: all var(--animation-duration);
}
.button:hover::before
  /* ,
  .button:focus::before */ {
  transform: translate(-5%, 0) skew(-30deg);
}
