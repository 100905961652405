.button {
  position: relative;
  display: inline-block;
  background: transparent;
  color: var(--text-color);

  border: 2px solid var(--border-color);
  font: inherit;
  line-height: 1;
  margin: 0;
  padding: var(--padding);
  border-radius: var(--border-radius);

  overflow: hidden;
  transition: all var(--animation-duration);

  z-index: 1;
  width: 100%;
}

.text {
  position: relative;
  font-size: clamp(15px, 2vw, 18px);
}
.button:hover
/* ,
.button:focus */ {
  color: var(--hover-text-color);
  border: 2px solid var(--hover-border-color);
}

.icon {
  margin: var(--icon-margin);
  width: var(--icon-width);
  padding: 0;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 130%;
  background-color: var(--hover-color);
  transform: translate(-110%, 0) skew(-30deg);
  transition: all var(--animation-duration);
}
.button:hover::before
/* ,
.button:focus::before */ {
  transform: translate(-5%, 0) skew(-30deg);
}
