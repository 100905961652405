:root {
  --transition-duration: 0.35s;
  --scale: 1.1;
}

.grid-item {
  width: 100%;
  height: 100%;
  transition: all var(--transition-duration);
  position: relative;
  // overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 1000%, 0% 1000%);

  min-height: 150px;

  .summary {
    // border: 2px solid var(--primary-color);
    position: relative;
    width: 100%;
    height: 100%;
    .image-wrapper {
      height: 80%;
      aspect-ratio: 16/9;
      max-width: 100%;
      margin: 0 auto;
      background-color: var(--image-background-color);

      overflow: hidden;

      .project-image {
        width: 100%;
        height: 100%;
        border: var(--image-border);
        object-fit: contain;
        // scale: 1;
        // transition: all var(--transition-duration);
      }
    }

    .title {
      color: white;
      font-weight: 400;
      font-size: 20px;
      margin: 2% 5%;
      text-align: center;
    }
  }
  .details {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-100%);
    padding: 5% 5% 8% 5%;

    background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 1));
    opacity: 0;
    transition: transform var(--transition-duration), opacity 0.4s ease-in-out;

    .content {
      opacity: 0;
      transition: all 0.4s ease-out 0.15s;
    }
    .title {
      color: var(--primary-color);
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 8px 0;
      text-align: start;
    }
    .description {
      font-size: 12px;
      line-height: 1.4;
    }

    .availableon-section {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      h6 {
        margin: 0;
        font-size: 13px;
        margin-bottom: 4px;
        // color: var(--primary-color);
      }
      .logo {
        width: 40px;
        height: 40px;
        margin-right: 5%;
        border-radius: 15%;
      }
      .store-wrapper {
        display: flex;
        flex-direction: row;
        gap: 4px;
        & > .store-icon {
          width: 20px;
          height: 20px;
          fill: white;
        }
      }
    }
    .tech-stack-section {
      .title {
        font-size: 12px;
      }
      .project-resource-btn {
        width: 90%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        min-height: 100px;
        justify-content: center;
        // align-items: center;
      }
      .wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        // justify-content: space-evenly;
        .tech-stack-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 25px;
          margin: 0 5px;
          .name {
            font-size: 6px;
            text-align: center;
            line-height: 1.4;
            margin: 15% 0;
          }
          img {
            height: 20px;
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(200%) contrast(2200%);
          }
        }
      }
    }
  }
}

.grid-item:hover {
  scale: var(--scale);
  cursor: pointer;

  .details {
    transform: translateY(0%);
    opacity: 1;
    overflow: auto;
  }
  .content {
    opacity: 1;
  }
}
