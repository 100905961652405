.contact-form-wrapper {
  background-color: var(--secondary-background-color);
  padding: 5% 0 3% 0;
}

.contact-form {
  margin: 40px auto 0 auto;
  width: 90%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 500px;
}
.textfield {
  width: 100%;
  margin-bottom: 0;
  .errormessage {
    color: yellow;
    font-size: 0.9em;
    padding: 0;
  }
}

.email,
.message {
  width: 100%;
}

.submit-wrapper {
  .submit {
    width: 100%;
  }
  .errormessage {
    color: yellow;
    text-align: center;
    padding: 16px 0 0 0;
  }
}

.request-success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
}

.request-success-message {
  p {
    font-size: clamp(12px, 3.5vw, 18px);
  }
}

// .email {
//   & > * {
//     // marginBottom: theme.spacing(2),
//     // backgroundColor: '#3b4353',
//     color: "white";
//     &:hover {
//       // backgroundColor: '#3b4353';
//       color: "white";
//     }
//     &.Mui-focused {
//       // backgroundColor: '#3b4353';
//       color: "white";
//     }
//     &.MuiFilledInput-underline:before {
//       // borderBottom: '2px solid #6f7b9b',
//     }
//     &.MuiFilledInput-underline:after {
//       // borderBottom: '2px solid #258b9e',
//     }
//   }
// }
// .css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
//   color: #1976d2;
// }

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms, transform 500ms;
}
