.wrapper {
  position: relative;
}
.fade-enter {
  opacity: 0;
  //   transform: translateX(-100%);
}
.fade-enter-active {
  opacity: 1;
  //   transform: translateX(0%);
}
.fade-exit {
  position: absolute;

  opacity: 1;
  transform: translateY(0%);
}
.fade-exit-active {
  position: absolute;

  opacity: 0;
  transform: translateY(-100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 1500ms, transform 1000ms;
}
