@import "../../../../bootstrapimports.scss";
.title {
  font-weight: bold;
  font-size: 27px;
  margin-top: 2.5em;
  margin-bottom: clamp(0.75em, 2vw, 2em);

  color: var(--primary-color);
  text-align: center;
}

.grid-view {
  display: grid;
  max-width: 1000px;
  width: 75%;
  margin: 0 auto;
  grid-template-columns: repeat(3, 30%);
  // grid-auto-rows: 150px;
  // grid-template-rows: 150px 150px;
  column-gap: 20px;

  row-gap: 32px;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

@include media-breakpoint-down(xl) {
  .grid-view {
    width: 100%;
  }
}

@include media-breakpoint-down(lg) {
  .grid-view {
    grid-template-columns: repeat(2, 40%);
  }
}

@include media-breakpoint-down(sm) {
  .grid-view {
    grid-template-columns: repeat(1, 75%);
  }
}
