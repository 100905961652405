.wrapper {
  width: 100%;
}
.progress-background {
  overflow: hidden;
  position: relative;
  background-color: #3e4555;
  border-radius: 2px;
  height: 20px;
  margin-bottom: 10px;
  width: 100%;
}

.progress-bar {
  margin-bottom: 20px;
  height: 20px;
  background-color: var(--primary-color);
  border-radius: 2px 0px 0px 2px;
}
