$profile_image_size: 120px;
.intro {
  height: 100vh; // Fallback for browsers that don't support svh
  height: 100svh;
  // min-height: 100vh;
  width: 100%;
  position: relative;
}
.intro-content {
  position: relative;

  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
.particles {
  background-color: #272b33;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.profileimg {
  border-radius: 50%;
  border: 0.2rem solid var(--primary-color);
  width: $profile_image_size;
  height: $profile_image_size;
  transition: all 0.5s ease-in-out 0s;
}

.profileimg:hover {
  width: calc($profile_image_size * 1.5);
  height: calc($profile_image_size * 1.5);
}

.name-text,
.greeting-text {
  margin: 15px 0;
  font-weight: 700;
  font-size: 2.2rem;
}

.name {
  color: var(--primary-color);
}

.intro-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.wave-emoji {
  animation: wave 2s linear 0s infinite alternate;
  display: inline-block;
  transform-origin: 70% 70%;
  /* an
    imation-fill-mode: forwards; */
}

.links {
  display: flex;
  padding: 20px;
  margin: 20px auto 0 auto;
  z-index: 2;
}
.links a img {
  width: 50px;
  height: 50px;
  transition: all 0.25s ease-in-out 0s;
}

.links a img:hover {
  width: 60px;
  height: 60px;
}

.links a:first-child {
  margin-right: 20px;
}

.scroll-down {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  /* left: 0; */
  /* right: 0; */
  bottom: 12%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-12deg);
  }

  20% {
    transform: rotate(12deg);
  }

  30% {
    transform: rotate(-12deg);
  }

  40% {
    transform: rotate(12deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 600px) {
  .name-text {
    padding-top: 5%;
    font-size: 1.5rem;
  }

  .greeting-text {
    font-size: 1.4rem;
  }

  .links {
    margin-top: 0;
  }

  .links a img {
    width: 35px;
    height: 35px;
  }
}
