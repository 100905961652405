.wrapper {
  width: 100%;

  position: sticky;
  top: 0;
  z-index: 999999999;
  background-color: #23252a;

  // position: relative;
  // z-index: 100;
  // clip: rect(0, 9999px, 9999px, -999px);
}
.navbar {
  // position: absolute;
  // bottom: 0px;

  width: 100%;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  border-bottom: 3px solid #0be779;
  pointer-events: fill;

  &.fixed {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.title {
  flex: 1;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  display: none;
}

.navbar a {
  cursor: pointer;
  font-weight: 600;
}
.navlink-wrapper {
  width: 20%;
}
.navitem {
  color: white;
  font-size: 1rem;
  transition: all 0.33s;
}

.navitem-selected,
.navitem:hover {
  font-size: 1.25rem;
  color: var(--primary-color);
  transition: all 0.33s;
}

.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.menu-icon-wrapper {
  display: none;
}

@media screen and (max-width: 700px) {
  .navlinks {
    display: none;
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .menu-icon-wrapper {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .title {
    display: initial;
  }
}
