.wrapper {
  position: relative;
  z-index: 100000;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100vh, 0% 100vh);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.absorbpointer {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: none;
}

.open {
  .navbar {
    transform: translateY(0%);
    transition: transform 400ms ease-in-out;
  }

  .absorbpointer {
    display: initial;
  }
}
.navbar {
  z-index: 5;
  position: absolute;
  background-color: #1a212e;
  display: flex;
  flex-direction: column;
  align-items: center;

  top: 0;
  width: 100%;
  padding: 5% 2.5%;
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;

  a {
    padding: 0.5em 0;
    cursor: pointer;
  }
}

.navitem {
  color: white;
  font-size: 1rem;
  transition: font-size 0.33s, color 0.33s;
}

.navitem-selected,
.navitem:hover {
  font-size: 1.25rem;
  color: var(--primary-color);
}

@media screen and (min-width: 700px) {
  .wrapper {
    display: none;
  }
}
