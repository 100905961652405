.wrapper {
  padding: 8px;
  width: 100;
  margin: 0 auto;
  text-align: center;
  font-weight: normal;
  background-color: var(--secondary-background-color);
  color: grey;

  font-size: clamp(10px, 1.8vw, 16px);
}
