/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

@font-face {
  font-family: "Courier";
  src: url("./assets/fonts/courier/CourierStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Courier";
  src: url("./assets/fonts/courier/CourierStd-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
:root {
  --primary-color: #0be779;
  --primary-background: #272b33;
  --secondary-background-color: #2c323f;
}
* {
  box-sizing: border-box;
}
strong {
  font-weight: 700;
}
html {
  font-size: 1rem;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: var(--primary-background);
  color: white;
  font-family: "Montserrat", sans-serif;
  min-width: 320px;
  overflow-x: hidden;
  font-weight: bold;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
