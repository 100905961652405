.experience-link {
  text-decoration: none;
  height: 100%;
  cursor: default;
}
.experience-card-wrapper {
  height: 100%;
  scale: 0.95;
  transition: 0.2s;
}
.experience-card {
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  align-items: center;
  min-height: 158px;
  transition: 0.2s;
  height: 100%;
  // border: 1px solid var(--primary-color);

  cursor: pointer;
}
// .experience-card:hover {
//   // box-shadow: -8px -8px 32px 0px var(--primary-color);
// }

// .experience-card:hover {
//   border: 1px solid var(--primary-color);
// }
.experience-card-wrapper:hover .experience-card {
  transform: translateY(-15px);
  background-color: #474f63;
}
.experience-card-top {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experience-card-top h2 {
  position: absolute;
  top: 30px;
  text-align: center;
  font-weight: 700;
  margin: 0 30px;
  font-size: 22px;
  text-shadow: 2px 2px 6px rgb(116, 108, 108);
  color: white;
  text-decoration: none;
  left: 0;
  right: 0;
  z-index: 100;
}
.image-wrapper {
  position: absolute;
  bottom: -50px;
  border-radius: 50%;
  background-color: var(--image-logo-background);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 100;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.experience-card-bottom {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: calc(100% - 150px);
  color: white;
  padding: 65px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.experience-card-bottom h2 {
  margin: 0;
  font-size: 26px;
}
.experience-card-bottom h3 {
  margin: 20px 0;
  font-size: 18px;
}
.experience-card-bottom ul {
  list-style-type: disc;
  text-align: left;
}
.experience-card-bottom li {
  font-size: 16px;
  font-weight: 300;
}
.experience-card-tech {
  margin-top: 20px;
}
.experience-card-tech > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -7px;
  margin-left: -7px;
}
.experience-card-tech > ul > li {
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(94, 106, 128);
}

.experience-bg-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.experience-bg {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
  background-color: var(--exp-background-color);
  background-image: var(--exp-background-url);
  // background-position: center;
  background-size: cover;
}
// .experience-bg-bottom {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
//   height: 10px;
//   box-shadow: 0px 8px 32px 0px var(--primary-color);
// }
.experience-bg-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0, 0.1);
  backdrop-filter: blur(2px);

  z-index: 10;
}
.logo-bg {
  border-radius: 50%;
}
.company-logo {
  max-width: 150px;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.techstackIcon {
  height: 16px;
  margin-right: 8px;
}

@media screen and (max-width: 599px) {
  .experience-card-wrapper:hover .experience-card {
    transform: translateY(0);
    background-color: #3b4353;
  }
  .experience-card-bottom {
    padding: 65px 15px 20px 15px;
  }
}
