.wrapper {
  width: 100%;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background-color: var(--primary-background);
  h3 {
    padding: 0;
    margin: 0;
    color: var(--primary-color);
  }

  .spacer {
    width: 100%;
    height: 3em;
  }
}
